.influencerImage {
  width: 250px;
  height: 250px;
}

.influencerImageSmall {
  width: 250px;
  height: 152px;
}

.imageContainer {
  position: relative;
}

.influencerDialogImage {
  border-radius: 5px;
  max-width: 200px;
}

.acceptButton {
  width: 130px;
  font-weight: bold;
}

.denyButton,
.denyButtonSmall,
.acceptButtonSmall {
  font-weight: bold;
  border-radius: 100px;
  border: 2px solid #000000;
  cursor: pointer;
  height: 32px;
  padding: 11px 24px;
  font-size: 17px;
  line-height: 22px;
  width: 130px;
  font-weight: bold;
  height: fit-content;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.denyButtonSmall,
.acceptButtonSmall {
  font-weight: bold;
  border-radius: 100px;
  border: 2px solid #000000;
  cursor: pointer;
  height: 32px;
  padding: 11px 24px;
  font-size: 17px;
  line-height: 22px;
  width: 103px;
  height: fit-content;
}

.denyButtonSmall,
.acceptButtonSmall {
  font-size: 15px;
  padding: 5px 10px;
  display: block;
  margin: 0 auto;
  background-color: #a890fe;
  border-color: #a890fe;
  border-radius: 10px;
}

.denyButton,
.denyButtonSmall {
  background: #ffffff;
  color: #000000;
}

.denyButton:active,
.denyButtonSmall:active {
  color: #fff;
  background: #000;
}

.denyButton:disabled,
.denyButtonSmall:disabled {
  border: 2px solid #9b9b9b;
  color: #9b9b9b;
}

.instagramPicture {
  width: auto;
  height: 125px;
  border-radius: 5px;
}

.influencerModal {
  border-radius: 8px;
}

.closeIcon {
  cursor: pointer;
  position: relative;
  align-self: flex-end;
  left: -1em;
  top: 1em;
  width: fit-content;
}

.tiktokText {
  background-color: #ff4150;
  margin: 10px 0px;
  padding: 2px 14px;
  border-radius: 5px;
  font-family: DMSans;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  width: 55px;
  height: 17px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 600px) {
  .italicH2 {
    margin-top: 3em;
  }
  .influencerModal {
    max-height: 620px;
  }
  .buttonsMobile {
    text-align: center;
  }
}

.noFeed {
  max-width: 100%;
}

.denyScreen {
  height: 210px;
  width: 225px;
  position: relative;
  bottom: 130px;
  background: white;
  border: 1px solid #a890fe;
  border-radius: 10px;
}

.denyScreenNoBottom {
  height: 250px;
  width: 215px;
  position: absolute;
  bottom: 36px;
  background: white;
  border: 2px solid black;
  border-radius: 10px;
}

.cardShadow {
  box-shadow: 5px 5px 20px -16px rgba(0, 0, 0, 0.6);
  border-radius: 5px;
}

.denyGridContainer {
  margin: 10px;
  max-width: 195px;
}

.denyGridHeader {
  text-align: center;
}

.denyGridButton {
  padding-top: 7px;
}

.closeDenyScreenIcon {
  height: 20px;
  width: 20px;
  margin-top: 5px;
}

.denyReason {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: black;
  font-size: 15px;
  max-height: 38px;
  min-width: 193px;
  text-align: left;
}

.denyReason:hover {
  background: #ebebeb;
  border-radius: 5px !important;
}

.buttonText {
  padding-top: 8px;
  padding-left: 8px;
  margin-bottom: 0 !important;
}

.detailsContainer {
  height: 130px;
  width: 250px;
  font-size: large;
  padding: 10px;
}

.denyButtonWide {
  background-color: white;
  border: 1px solid #a890fe;
  color: #a890fe;
  border-radius: 10px;
  margin-top: 6px;
  padding: 2px;
  font-size: small;
  height: 35px;
  width: 100%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.backDenyScreenIcon {
  width: 10px;
  height: 10px;
}

.denyScreenText {
  height: 225px;
  width: 225px;
  position: relative;
  bottom: 145px;
  background: white;
  border: 1px solid #a890fe;
  border-radius: 10px;
  white-space: nowrap;
}

.denyTextField {
  background-color: #f2f2f2;
  resize: none;
  height: 125px;
  width: 95%;
  border: none;
  margin-top: 10px;
  margin-left: 8px;
  border-radius: 5px;
  outline: none;
  font-family: DMSans;
}

.denyButton {
  font-size: 15px;
  padding: 5px 10px;
  margin-left: 9px;
  background-color: #a890fe;
  border-color: #a890fe;
  color: white;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.denyButton:disabled {
  border: none;
  font-size: 15px;
  padding: 7px 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.openModalIcon {
  filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.5));
}

.openModalButton {
  position: absolute !important;
  right: 5px;
  top: 5px;
}
