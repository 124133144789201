.campaignCard {
    max-width: 500px;
    background: #ffffff;
    /* Greys / Middle */
    margin-top: 2.5em;
    box-shadow: none;
    border: 1px solid #f2f2f2;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: none;

    cursor: pointer;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.cardPadding {
    padding: 0em 2em 1em 2em;
}

.cardContainer {
    flex-direction: column;
}

.card-header-container {
    justify-content: space-between;
    align-items: baseline;
}

.campaignCardMiddle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 35px;
    margin-bottom: 1em;
}

.CardInfo {
    padding-left: 3em;
    padding-right: 1.25em;
}

.campaignImageContainer {
    flex-basis: 270px;
}
.campaignImage {
    width: 270px;
    height: 160px;
    border-radius: 5px;
}
.campaignStatus {
    margin-top: 1.25em;
}

.greenDot {
    color: #7dca77;
}

.cardItem {
    flex-grow: 0;
    max-width: 50%;
    flex-basis: 40%;
}
.campaignCardStatus {
    margin-left: 0.75em;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 600px) {
    .campaignCardMiddle {
        text-align: center;
        width: 100%;
    }
    .campaignStatus {
        margin-top: 0em;
        justify-content: center;
    }
}
