.container {
  padding-left: 32px;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
  height: 60vh;
}

.errorContainer {
  text-align: center;
}

.form {
  margin-left: 64px !important;
  max-width: 430px !important;
}

.textField {
  margin-top: 16px !important;
}

.passwordContainer {
  margin-top: 16px !important;
  display: flex;
}

.buttonContainer {
  margin-top: 44px !important;
  display: flex;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 600px) {
  .form {
    margin-left: 1em !important;
    margin-right: 1em !important;
  }
}
