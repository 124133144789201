.freebeeH1 {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #121212;
}

.secondTitleLine {
  margin-top: 1em;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
}

.createCampaignLabel {
  color: #a890fe;
  letter-spacing: 0.2px;
  font-size: 15px;
  line-height: 17px;
  font-family: Georgia;
  font-style: italic;
  font-weight: bold;
  cursor: pointer;
}

.createCampaignFab {
  height: 24px;
  margin-left: 12px;
  width: 24px;
  height: 24px;
  background: #a890fe;
  color: white;
}

.createCampaignFab.MuiFab-root {
  margin-left: 12px;
  width: 24px;
  height: 24px;
  background: #a890fe;
  color: white;
  min-height: 24px;
  box-shadow: none;
}

.campaignCardContainer {
  justify-content: space-around;
}

.modal {
  display: flex;

  align-items: center;
  justify-content: center;
}

.modalInner {
  background-color: #fff;
  border-radius: 8px;
  outline: none;
  width: 560px;
  height: 354px;
  padding-left: 6em;
}

.modalHeader {
  padding: 20px 20px 20px 0px;
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.modalFooter {
  padding: 20px 20px;
  text-align: right;
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.modeSelector {
  border: 1px solid #4f4f4f;
  box-sizing: border-box;
  border-radius: 8px;
  width: 299px;
  height: fit-content;
  padding: 8px;
}

.modeSelectorActive {
  border: 1px solid #a890fe;
}

.featureList {
  padding: 2px 0px 2px 10px;
  list-style-image: url(../assets/icons/ic_checkmark.svg);
  display: list-item;
}

.featureListDisabled {
  padding: 2px 0px 2px 10px;
  display: list-item;
  color: #9b9b9b;
}

.paddingStandardMode {
  padding-bottom: 1em;
}
.paddingExpertMode {
  padding-bottom: 1em;
}

.offlineCampaign {
  text-align: end;
}

.guidlinesList {
  list-style-type: none;
}

.guidlinesList li::before {
  content: '\2022';
  color: #a890fe;
  font-weight: bold;
  display: inline-block;
  width: 16px;
  height: 8px;
}

.priceText {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  text-align: end;
  padding-right: 1em;
}

.modeSelectContent {
  height: 40vh;
}

.background {
  background-color: #fdfdfd;
  position: fixed;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  z-index: -1;
}
.bgimg {
  position: absolute;
  top: 50;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  min-width: 50%;
  min-height: 50%;
  z-index: -1;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 600px) {
  .modeSelector {
    border: none;
    box-sizing: none;
    border-radius: none;
    width: 0%;
    height: 0%;
    padding: 0;
  }
  .paddingStandardMode {
    padding-right: 8px;
  }
  .paddingExpertMode {
    padding-left: 0;
    padding-right: 8px;
  }
  .offlineCampaign {
    text-align: left;
  }
}

.subheading {
  font-family: Georgia !important;
  font-style: italic;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.4px;
  color: #121212;
  margin: 10px 0;
}

.budgetReserved {
  margin: 0 !important;
  line-height: 18px;
}

.notificationDot {
  width: 9px;
  height: 9px;
  font-size: 12px;
  color: white;
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 100%;
  background: #a890fe;
  position: absolute;
  right: 10px;
}

.notificationDotSidebar {
  font-size: 12px;
  padding: 2px 7px;
  color: black;
  text-align: center;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  border-radius: 5px;
  position: absolute;
  right: 10px;
  background: #f7f98f;
}

.reChargebg {
  background: #fff0e4;
  border-radius: 5px;
  width: 191px;
  height: 210px;
}

.creditBox {
  padding: 16px;
  margin: 16px;
}

.creditBtn {
  font-size: 14px;
  min-width: 170px;
  max-height: 40px;
  background-color: #a890fe;
  padding: 8px 16px 8px 16px;
}

.sideBarContainer {
  background-color: white;
}

.creditIcon {
  position: absolute;
  top: 525px;
  left: 85px;
  width: 80px;
}

.creditContainer {
  padding-top: 4em;
}

.creditBoxText {
  margin: 16px 0px 16px 0px !important;
  font-size: small;
}

.creditPayXs {
  margin: 0 !important;
  padding: 2px !important;
  font-size: small;
}

.creditPaySm {
  margin: 0 !important;
  padding: 4px !important;
  font-size: medium;
}

.creditFirstNumberXs {
  margin: 0 !important;
  padding: 0px !important;
  font-size: large;
}

.creditFirstNumberSm {
  margin: 0 !important;
  padding: 0px !important;
  font-size: x-large;
}

.creditGetXs {
  margin: 0 !important;
  padding: 1px !important;
  font-size: small;
}

.creditGetSm {
  margin: 0 !important;
  padding: 4px !important;
  font-size: medium;
}

.creditSecondNumberXs {
  margin: 0 !important;
  padding: 1px !important;
  font-size: large;
}

.creditSecondNumberSm {
  margin: 0 !important;
  padding: 4px !important;
  font-size: xx-large;
}

.creditDialogText {
  padding-left: 18px;
}

.modalSubmitButton {
  margin-bottom: 5%;
}

.creditBackground {
  background-color: #fff0e4;
}

.dashBoardHeader {
  font-size: large;
}

.dotHeader {
  width: 5px;
  margin: 0 6px 3px 6px;
}

.yourCampaignHeader {
  color: black;
  margin-top: 100px !important;
  margin-bottom: 16px;
  font-weight: bold;
}

.yourCampaignsTotalHeader {
  color: #bdbdbd;
}

.createCampaignContainer {
  background-color: white;
  border-style: solid;
  border-width: 2px;
  border-color: #f2f2f2;
  border-radius: 5px;
  padding: 21px;
  margin-bottom: 10px;
  margin-top: 10px;
  min-height: 160px;
}

.createCampaignHeader {
  font-size: large;
}

.createCampaignButton {
  margin-top: 8px !important;
}

.inspirationCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  border: 1px solid #8a66ff;
  height: 100%;
}

.videoContainer {
  position: relative;
  width: 100%;
  aspect-ratio: 9 / 16;
  border-radius: 10px;
  overflow: hidden;
  flex-shrink: 0;
}

.videoContainer > div {
  height: 100%;
  width: 100%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.inspirationContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  width: 100%;
}

.inspirationTitle {
  font-size: 1.25rem;
  font-weight: bold;
  color: #3b3b3b;
  margin: 10px 0;
  text-align: center;
}

.primaryText {
  color: #8a66ff;
}

.secondaryText {
  color: #585858;
}

.inspirationDetails {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

ul {
  padding-left: 16px;
  font-size: 1rem;
  color: #585858;
  text-align: left;
  flex-grow: 1;
  margin-top: 0;
}

ul li {
  margin: 5px 0;
  font-size: 12px;
}
