.campaignSidebar {
  width: 248px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 128px);
  border-right: solid 1px #ededed;
}

.imageContainer {
  position: relative;
  margin-top: 2em;
  text-align: center;
}

.yellowWave {
  position: absolute;
  bottom: 25%;
  right: 80px;
}

.orangeWave {
  position: absolute;
  left: 40%;
}

.brandname {
  background: #000000;
  border-radius: 5px;
  color: #ffffff;
  font-family: DMSans;
  text-align: center;
}

.buttonAddBudget {
  color: #a890fe;
  letter-spacing: 0.2px;
  font-size: 15px;
  line-height: 17px;
  font-family: Georgia;
  font-style: italic;
  font-weight: bold;
  cursor: pointer;
}

.buttonGrid {
  align-self: center;
  margin-left: 1.5em;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-direction: row;
}

.manageBrandLabel {
  margin-top: 2em;
}

.selectLabelModal {
  width: 368px;
}
.sidebarItem {
  padding-left: 1em;
  padding-right: 1.5em;
  padding-bottom: 2em;
}
.h2freebee {
  text-align: left;
  margin-top: 56px;
}

.drawerLogo {
  z-index: 1000;
  position: relative;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 96px;
  height: 96px;
  border-radius: 5px;
  padding: 1em;
}
.notificationDotSidebar {
  width: 9px;
  height: 9px;
  font-size: 12px;
  color: black;
  font-weight: bold;
  text-align: center;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  border-radius: 100%;
  background: #a890fe;
  position: absolute;
  right: 10px;
}
.sidebarChat {
  font-family: DMSans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #121212;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.sidebarText:hover {
  font-family: DMSans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #121212;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
