.campaignNav {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 3em; /*abstand*/
}

.campaignNav::hover {
  color: #a890fe;
}

.detailsContainer {
  margin-top: 24px;
}
.navText {
  margin-right: 1em;
}

.navTextLogoDiv {
  display: flex;

  position: relative;
  align-items: center;
}

.navTextLogoDiv::after {
  position: absolute;
  border-bottom: 2px solid #a890fe;
  bottom: -0.75em;
  width: 100%;
  content: '';
}

.navTextDisabled {
  display: flex;

  position: relative;
  align-items: center;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 600px) {
  .campaignImage {
    max-width: 324px;
    width: 100%;
  }
  .paddingContainerRight {
    padding-left: 0;
  }
}

.link {
  text-decoration: underline;
  margin: 0 !important;
  padding: 0 !important;
}

.campaignDashboardConainter {
  display: flex;
}

.dashboard {
  padding-right: 2em;
  padding-left: 2em;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
}
