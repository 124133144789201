.participantsContainer {
  display: flex;
}

.Content {
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.ContentInner {
  padding-right: 5em;
  padding-left: 2em;
}

.noParticipantsContainer {
  padding-top: 5em;
}

.noParticipants {
  max-height: 219px;
  max-width: 427px;
  width: 100%;
  height: auto;
}
.notAuth {
  text-align: center;
}
.influencerPic {
  height: 2em;
  width: 2em;
  border-radius: 50%;
}

.tableOther {
  min-width: 1000px;
}

.tableDelivery {
  min-width: 1400px;
}

.root {
  overflow-x: auto;
  max-width: calc(100vw - 248px);
  width: 100%;
}

@media (max-width: 959px) {
  .root {
    max-width: 100vw;
  }
}

.insightsCard {
  min-height: 167px;
  box-shadow: 0px 3px 5px rgba(153, 153, 153, 0.2), 0px 1px 18px rgba(153, 153, 153, 0.12), 0px 6px 10px rgba(153, 153, 153, 0.14);
  border-radius: 5px;
  align-items: center;
  text-align: center;
  background: white;
}
.h4 {
  font-family: DMSans;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #121212;
}

@media only screen and (min-device-width: 601px) and (max-device-width: 959px) {
  .root {
    overflow-x: auto;
    max-width: calc(100vw);
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 600px) {
  .Content {
    margin: 0;
    padding-right: 1em;
    padding-left: 1em;
    width: 100%;
    flex-wrap: wrap;
    box-sizing: border-box;
  }

  .root {
    max-width: 100vw;
  }
}
