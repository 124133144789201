.noApplicantsContainer {
    padding-top: 5em;
}

.noApplicants {
    max-height: 219px;
    max-width: 427px;
    width: 100%;
    height: auto;
}

.cardContainer {
    background-color: white;
    border-style: solid;
    border-width: 2px;
    border-color: #f1f1f1;
    border-radius: 10px;
    margin-left: 0 !important;
    padding: 30px 50px 30px 50px;
    margin-bottom: 30px;
    margin-top: 30px;
}
