html {
  position: relative;
  min-height: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
}

.pageContentWrapper {
  margin-bottom: 132px;
}

.container-100vh {
  min-height: 80vh;
}

.row {
  margin: 0px;
}

/* Calendar */

.margin-calendar {
  margin: auto;
}

/* Navbar */

.navbar {
  font-size: 14px;
  font-weight: 300;
  color: #333;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px #ddd;
}

@media (min-width: 992px) {
  .navbar li {
    margin-left: 2em;
    margin-right: 2em;
  }

  .navbar-brand {
    margin-left: 2em;
    margin-right: 2em;
  }
}

.nav-link-span {
  padding: 20px 0px !important;
  position: relative;
}

.nav-link-span::before {
  transition: 300ms;
  height: 2px;
  content: '';
  position: absolute;
  background-color: #fdc628;
}

.nav-link-ltr::before {
  width: 0%;
  bottom: 10px;
}

.nav-link-ltr:hover::before {
  width: 100%;
}

.nav-link {
  cursor: pointer;
}

/* Campaign List */

.campaigns-list-height {
  min-height: 95vh;
  max-height: 95vh;
  overflow: auto;
}
.campaigns-list-height::-webkit-scrollbar {
  display: none;
}

.campaign-card {
  padding: 10px;
  position: relative;
  text-align: center;
  cursor: pointer;
}

.campaign-card img {
  border-radius: 5px;
}

.campaign-list-title {
  font-weight: bold;
  font-size: 16px;
}

.bottom-left {
  position: absolute;
  bottom: 20px;
  left: 16px;
}

.top-left {
  position: absolute;
  top: 12px;
  left: 16px;
}

.bottom-left p {
  margin: 0;
}

.hidden {
  visibility: hidden;
}

/* Text Styles */
body {
  font-family: DMSans !important;
  color: #5c5c5c;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 22px;
  letter-spacing: 0 !important;
}

.display {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 41px;
  color: #121212;
}

.card-insight-subtext {
  font-size: 14px;
  padding-bottom: 30px;
}

.insights-details-table td {
  padding: 5px;
}

.insightcard-firstrow {
  margin-bottom: 13px;
}

.insightcard-secondrow {
  margin-bottom: 16px;
}

.error-text {
  color: #f44336;
}
/* Footer*/

.footer {
  margin-top: 87px;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  width: 100%;
}

.footer .col-12 {
  padding-top: 5px;
  padding-bottom: 5px;
}

a {
  color: inherit;
}
/* Shop */

@media (max-width: 575.98px) {
  #shopContainer .container {
    min-width: 100%;
  }
}

h1 {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  color: #121212;
}

h2 {
  font-family: DMSans;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.3px;
  color: #121212;
}

/* h3 {
  font-family: Georgia;
  font-style: italic;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.4px;
  color: #121212;
} */

h4 {
  font-family: DMSans;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  color: #121212;
}

label {
  font-family: DMSans !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #121212;
}

.description {
  font-family: DMSans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #5c5c5c;
}

p {
  margin: 0px;
}

/* Button Styles */
button {
  background: #000000;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  min-height: 32px;
  padding: 11px 24px 11px 24px;
  color: #fff;
  font-size: 17px;
  line-height: 22px;
}

button.MuiAutocomplete-clearIndicatorDirty {
  line-height: normal;
  height: 20px;
}

button:active {
  background: #4f4f4f;
}

button:disabled {
  color: #5c5c5c;
  background: #ededed;
}

.whiteButton {
  display: block;
  font-weight: bold;
  background: #ffffff;
  border-radius: 100px;
  border: 2px solid #000000;
  cursor: pointer;
  min-height: 32px;
  padding: 11px 24px 11px 24px;
  color: #000000;
  font-size: 17px;
  line-height: 22px;
  width: fit-content;
  height: fit-content;
  margin: 0 auto;
}

.whiteButton:active {
  color: #fff;
  background: #000;
}

.whiteButton:disabled {
  border: 2px solid #9b9b9b;
  color: #9b9b9b;
}

.cancelButton {
  font-weight: bold;
  background: #ffffff;
  cursor: pointer;
  height: 40px;
  padding: 11px 24px 11px 24px;
  color: #9b9b9b;
  font-size: 17px;
  line-height: 22px;
  width: fit-content;
  height: fit-content;
}

.cancelButton:active {
  color: #fff;
  background: #000;
}

.cancelButton:disabled {
  color: #9b9b9b;
}

.outline {
  background: #ffffff;
  border: 2px solid #000000;
  color: #000000;
}

.outline:active {
  background: #000000;
  border: 2px solid #000000;
  color: #fff;
}

.outline:disabled {
  border: 2px solid #9b9b9b;
  color: #9b9b9b;
}

.coloured {
  background: #ff4150;
  color: #ffffff;
}

.coloured:active {
  background: #fc6d78;
  color: #ffffff;
}

.coloured:disabled {
  background: #fc6d78;
  color: #ac3b44;
}

.italic {
  height: 100%;
  padding: 0;
  background: transparent;
  color: #000000;
  font-family: Georgia;
  font-style: italic;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: 0.2px;
}

.link {
  height: 100%;
  padding: 0;
  background: transparent;
  color: #a890fe;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: 0.2px;
}

.link:active {
  background: #fff;
  color: #ba6929;
}

.link:disabled {
  background: #fff;
  color: #9b9b9b;
}

.greylink {
  height: 100%;
  padding: 0;
  background: transparent;
  color: #5c5c5c;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  letter-spacing: 0.2px;
}

.greylink:disabled {
  background: #fff;
  color: #9b9b9b;
}

.tiktokText {
  z-index: 100;
  font-size: 1em;
  display: flex;
  position: relative;
  color: black;
}

.tiktokText::before {
  content: attr(data-letter);
  z-index: -5;
  position: absolute;
  transform: translate(1px, 1px);
  color: #fd2c54;
  top: 0;
  left: 0;
}

.tiktokText::after {
  content: attr(data-letter);
  z-index: -1;
  position: absolute;
  transform: translate(-1px, -1px);
  color: rgba(34, 246, 232, 0.5);
  top: 0;
  left: 0;
}
/* Textfields */
.MuiFormLabel-root.Mui-focused {
  color: #a890fe !important;
}

.MuiInput-underline:after {
  border-color: #a890fe !important;
}

.MuiFormLabel-root.Mui-focused.Mui-error {
  color: #f44336 !important;
}

.kep-login-facebook {
  height: initial;
  line-height: initial;
}

.tiktok-embed iframe {
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  -moz-transform-scale: scale(0.7);
  -moz-transform-origin: top left;
  -webkit-transform-origin: top left;
  -o-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
}

/*
  	mui stepper
*/

.MuiStepIcon-active {
  color: #a890fe !important;
}

.MuiStepIcon-completed {
  color: #a890fe !important;
}

.MuiStepLabel-label {
  font-family: Georgia !important;
  font-style: italic;
}

@media (max-width: 599px) {
  .MuiStepper-root {
    padding: 24px 0 !important;
  }
}

/*
************************************************************
                    GENERAL STYLING
************************************************************
*/

.freebee-orange {
  color: #a890fe;
}

/*
*********
  LABEL
*********
*/
/* Textfields */
.MuiFormLabel-root.Mui-focused {
  color: #a890fe !important;
}

.MuiInput-underline:after {
  border-color: #a890fe !important;
}

.MuiFormLabel-root.Mui-focused.Mui-error {
  color: #f44336 !important;
}

.MuiInput-underline.Mui-error:after {
  border-color: #f44336 !important;
}

.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: solid !important;
}

.createCampaignFab {
  height: 24px;
  margin-left: 12px;
  width: 24px;
  height: 24px;
  background: #a890fe;
  color: white;
  font-size: 20px;
  line-height: 20px;
  padding-left: 1px;
}

.createCampaignFab.MuiFab-root {
  margin-left: 12px;
  width: 24px;
  height: 24px;
  background: #a890fe;
  color: white;
  min-height: 24px;
  box-shadow: none;
  font-size: 20px;
  line-height: 20px;
  padding-left: 1px;
  border-radius: 50%;
}

.loadingFreebee {
  color: #a890fe;
  align-content: center;
  text-align: center;
  width: 100%;
}

.loadingFreebee.MuiCircularProgress-colorPrimary {
  color: #a890fe;
}

.loadingWhite {
  color: #ffffff;
}
.loadingWhite.MuiCircularProgress-colorPrimary {
  color: #ffffff;
}
.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  color: #a890fe !important;
}

/*
************************************************************
                        LOGIN CSS
************************************************************
*/
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

.expertModeText {
  background: #a890fe;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  margin: 10px 0px;
  padding: 2px 7px;
  color: #ffffff;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  width: fit-content;
  height: fit-content;
}

.standardModeText {
  background: #000000;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  margin: 10px 0px;
  padding: 2px 7px;
  color: #ffffff;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  width: fit-content;
  height: fit-content;
}

.overtimeModeText {
  background: #ff4150;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  margin: 10px 0px;
  padding: 2px 7px;
  color: #ffffff;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  width: fit-content;
  height: fit-content;
}

.checkUploadText {
  background: #f7f98f;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  margin: 10px 0px;
  padding: 2px 7px;
  color: #000000;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  width: fit-content;
  height: fit-content;
}

.selectLabel.MuiSelect-icon {
  background-image: url(assets/icons/ic_dropdown.svg);
}

.selectLabel.MuiSelect-iconOpen {
  background-image: url(assets/icons/ic_dropup.svg);
}

/* Datepicker */

/* .MuiSvgIcon-root  {
  color: #a890fe !important;
} */

.MuiPickersToolbar-toolbar {
  background: #a890fe !important;
}

.MuiPickersDay-daySelected {
  background: #a890fe !important;
}

.MuiTypography-root {
  margin: 0px;
}

/* Radio */

.MuiRadio-colorSecondary.Mui-checked {
  color: #a890fe !important;
}

/* Select */

/* .MuiSvgIcon-root.MuiSelect-icon {
  color: #a890fe !important;
} */

/* Checkbox */

/* .MuiSvgIcon-root  {
  color: #a890fe !important;
} */

.MuiIconButton-colorSecondary {
  color: #a890fe !important;
}

/* Dialog */
.MuiDialogActions-root {
  padding: 48px 2em 2em 2em !important;
}
/* 
.MuiDialog-paper {
  padding: 1em 2em;
} */

/* Stripe */
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

/*
************************************************************
                        Chat CSS
************************************************************
*/

.chat-list {
  min-width: 240px;
  max-width: 380px;
  overflow: auto;
}

.rce-citem-body--bottom-status {
  width: 9px;
  height: 9px;
}

.rce-citem-body--bottom-status > span {
  color: #a890fe !important;
  background: #a890fe !important;
  width: 9px !important;
  height: 9px !important;
}

.rce-mbox-photo--img {
  cursor: pointer;
}

.rce-mbox-text {
  max-width: 400px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 600px) {
  .MuiDialog-paper {
    padding: 1em 1em !important;
  }
  .MuiDialogActions-root {
    padding: 0px !important;
  }
}

/* Fonts */

@font-face {
  font-family: DMSans;
  src: url(assets/fonts/dm_sans/DMSans-Regular.ttf);
}
@font-face {
  font-family: DMSans;
  font-weight: bold;
  src: url(assets/fonts/dm_sans/DMSans-Bold.ttf);
}
@font-face {
  font-family: DMSans;
  font-weight: 200;
  src: url(assets/fonts/dm_sans/DMSans-ExtraLight.ttf);
}
@font-face {
  font-family: DMSans;
  font-weight: 300;
  src: url(assets/fonts/dm_sans/DMSans-Light.ttf);
}
@font-face {
  font-family: DMSans;
  font-weight: 500;
  src: url(assets/fonts/dm_sans/DMSans-Medium.ttf);
}
@font-face {
  font-family: DMSans;
  font-weight: 500;
  src: url(assets/fonts/dm_sans/DMSans-Medium.ttf);
}
@font-face {
  font-family: DMSans;
  font-weight: 600;
  src: url(assets/fonts/dm_sans/DMSans-SemiBold.ttf);
}

@font-face {
  font-family: 'Bricolage Grotesque';
  src: url(assets/fonts/bricolage_grotesque/BricolageGrotesque-Bold.ttf);
}

.MuiSlider-root {
  color: #a890fe !important;
}

.tac {
  text-align: center;
}

.tar {
  text-align: right;
}

@media (max-width: 575.98px) {
  .tal-xs {
    text-align: left !important;
  }
}

/* CHAT */

.rce-container-clist {
  padding-right: 16px;
  padding-left: 5px;
}

.rce-container-citem {
  margin-bottom: 8px;
}

.rce-citem {
  border-radius: 16px;
}

.rce-citem-body--top-title {
  font-size: 12px !important;
  font-weight: 500;
}

.rce-citem-body--bottom-title {
  font-size: 12px !important;
  max-width: 200px;
}

.rce-input {
  font-family: DMSans;
  width: 100%;
}

/* UGC Campaign Form */
.videoPlayerWrapper video {
  border-radius: 8px 8px 0 0;
  /* object-fit: cover; */
  max-height: 250px;
}

.roundedVideo video {
  border-radius: 8px;
}

.ugc-subscription-dialog-video-wrapper video {
  border-radius: 10px;
  border: 4px solid white;
}

.deny-dialog-video-wrapper video {
  border-radius: 10px;
  border: 4px solid white;
  aspect-ratio: 9/16;
}

/* ZStack */
.zstack {
  display: grid;
  align-items: center;
  justify-items: center;
}
.zstack > * {
  grid-area: 1/1/1/1;
  display: flex;
  justify-content: center;
  align-items: center;
}
