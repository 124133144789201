.influencerCard {
  width: 200px;
  height: 230px;
  background: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  margin: auto;
}

.influencerImage {
  width: 200px;
  height: 200px;
  border-radius: 5px;
}

.influencerName {
  margin-bottom: 0;
  margin-top: 11px;
}

.influencerFollower {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
}

.influencerInstagram {
  color: #005da0;
  font-weight: bold;
}
.imageContainer {
  position: relative;
}

.influencerModalImage {
  border-radius: 5px;
  max-width: 225px;
}

.acceptButton {
  width: 130px;
  font-weight: bold;
}

.italicH2 {
  font-family: Georgia;
  font-style: italic;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.4px;
  margin-bottom: 6px;
}

.campaignName {
  margin-top: 0;
  margin-bottom: 1.5em;
}

.postingType {
  margin-bottom: 0;
  margin-top: 11px;
  padding-right: 1em;
  text-align: end;
}

.influencerModal {
  border-radius: 8px;
}

.closeIcon {
  cursor: pointer;
  position: relative;
  align-self: flex-end;
  left: -1em;
  top: 1em;
  width: fit-content;
}

.acceptButton {
  width: 130px;
  font-weight: bold;
  height: 48px;
}

.denyButton {
  font-weight: bold;
  background: #ffffff;
  border-radius: 100px;
  border: 2px solid #000000;
  cursor: pointer;
  height: 44px;
  padding: 11px 24px 11px 24px;
  color: #000000;
  font-size: 17px;
  line-height: 22px;
  height: fit-content;
}

.CP {
  max-width: 170px;
}

.dialog {
  min-width: 800px;
}

@media (max-width: 800px) {
  .dialog {
    min-width: 0px
  }
}
.orangeWaveLeft {
  position: absolute;
  bottom: 20%;
  left: 0;
}

.orangeWaveRight {
  position: absolute;
  right: 0;
  top: 20%;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 600px) {
  .italicH2 {
    margin-top: 3em;
  }
  .influencerModal {
    max-height: 620px;
  }
  .buttonsMobile {
    text-align: center;
  }
}

.createCampaignButton {
  border-radius: 100px;
  border: none;
  cursor: pointer;
  height: 44px;
  padding: 11px 24px 11px 24px;
  color: #fff;
  font-size: 17px;
  line-height: 22px;
  background: #ff4150;
  font-weight: 600;
}