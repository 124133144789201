.noParticipantsContainer {
  padding-top: 5em;
}

.noParticipants {
  max-height: 219px;
  max-width: 427px;
  width: 100%;
  height: auto;
}

.cardContainerSmall,
.cardContainer {
  background-color: white;
  border-style: solid;
  border-width: 1.5px;
  border-color: #f1f1f1;
  border-radius: 10px;
  margin-left: 0 !important;
  padding: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
  overflow: auto;
}

.cardContainerSmall {
  margin-left: '40px !important';
}

.iconContainer {
  color: #4f4f4f;
}

.icon {
  width: 30px;
  margin-right: 10px;
}

.amountContainer {
  font-size: xx-large;
  color: #4f4f4f;
}

.beingEditedLabel,
.producedTextLabel,
.doneTextLabel,
.deliveredTextLabel,
.waitingTextLabel {
  margin: 10px 0px;
  padding: 6px 7px;
  color: white;
  border-radius: 5px;
  font-style: normal;
  font-size: 13px;
  line-height: 14px;
  width: fit-content;
  height: fit-content;
  background: #a890fe;
}

.doneTextLabel {
  background: #e0e0e0;
}
.beingEditedLabel {
  background: #a890fe;
}

.todoWhite,
.todoBlack,
.todoTextLabel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  max-width: 17em;
  text-transform: uppercase;
  border-radius: 10px;
  color: white;
}

.redBackground {
  background: #ff4150 !important;
}
.redText {
  color: #ff4150 !important;
  background: white !important;
}

.todoWhite {
  background: #e0e0e0;
}

.todoBlack {
  background: black;
  cursor: pointer;
}

.tableHeader {
  font-weight: 600;
  font-size: 15px;
  color: #828282;
}

.tableBody {
  font-weight: 500;
  color: #4f4f4f;
}

.influencerPicture {
  object-fit: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
