.feedGridContainer {
  width: 100%;
  padding-bottom: 25px;
  overflow: hidden;
  margin: 0 !important;
}

.gridTile > div {
  border-radius: 10px;
  box-shadow: 0px 3px 5px rgb(153 153 153 / 20%), 0px 1px 18px rgb(153 153 153 / 12%), 0px 6px 10px rgb(153 153 153 / 14%);
}

.feedPostWrapper {
  height: 100%;
}

.feedPostWrapper > video {
  max-width: 100%;
}

.feedPostOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 10%);
}

.feedPostOverlayInner {
  width: 100%;
  height: 100%;
  position: relative;
}

.infoContainer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  cursor: pointer;
}

.infoContainer_outer {
  padding: 0 0;
}

.infoContainer_inner {
  text-shadow: 0px 3px 5px rgb(153 153 153 / 20%), 0px 1px 18px rgb(153 153 153 / 12%), 0px 6px 10px rgb(153 153 153 / 14%);
  background-color: white;
  text-align: center;
  color: black;
  margin: 0 0.75em;
  padding: 0.75em;
  border-radius: 10px 10px 0px 0px;
}

.infoContainer_inner:hover {
  background-color: #ededed;
}

.infoContainer_inner > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.infoProfileContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.infoProfilePicture {
  height: 2em;
  width: 2em;
  border-radius: 50%;
  margin-right: 0.5em;
}

.payedIcon {
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 7px;
  right: 7px;
  width: 24px;
  height: 24px;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.5));
}

.videoControls {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
}

.videoControls > div {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
}

.postWrapper {
  height: 100%;
  border-radius: 10px;
}

.postWrapper > img::after {
  content: 'Cannot preview post';
  font-size: 10px;
  color: #646464;
  display: block;
  position: absolute;
  z-index: 2;
  top: calc(50% - 12.5px);
  width: 100%;
  height: 25px;
  text-align: center;
}

.postWrapper > img::before {
  content: '';
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  background-color: #ccc;
  position: absolute;
  top: 50%;
  left: -2px;
  transform: translateY(-50%);
}

.storyWrapper > img::after {
  content: '';
  background-image: url('../../assets/illustrations/im_no-participants.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: block;
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 10px;
}

.noParticipantsFeed {
  display: flex;
  height: 100%;
  justify-items: center;
  align-items: center;
}

.noParticipantsFeed > img {
  max-width: 100%;
}

.insightsContainer {
  display: flex;
  box-sizing: border-box;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 4px;
}

.noParticipantsContainer {
  padding-top: 5em;
}

.noParticipants {
  max-height: 219px;
  max-width: 427px;
  width: 100%;
  height: auto;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalInner {
  background-color: #fff;
  border-radius: 8px;
  outline: none;
  margin: 10rem auto;
}

.modalBody {
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: center;
}

.modalBody > * {
  width: 100%;
}

.modalHeader {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalFooter {
  padding: 20px;
  text-align: right;
}

.closeIcon {
  cursor: pointer;
}

.evaluateLink {
  color: #a890fe;
  text-decoration: underline;
}

.cardContainer {
  background-color: white;
  border-style: solid;
  border-width: 2px;
  border-color: #f1f1f1;
  border-radius: 10px;
  margin-left: 0 !important;
  padding: 30px;
  margin-bottom: 30px;
  margin-top: 10px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
