.flex {
  display: flex;
}

.campaignNav {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 2em;
}

.campaignNav::hover {
  color: #a890fe;
}

.detailNav {
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  width: 100%;
  align-items: center;
}
.detailsContainer {
  margin-top: 24px;
}
.navText {
  margin-right: 1em;
}

.campaignStatus {
  align-items: baseline;
}

.reward {
  margin-top: 1em;
  align-items: baseline;
}

.rewardValue {
  background: #a890fe;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  margin-left: 1em;
  padding: 2px 7px;
  color: #ffffff;
  border-radius: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  width: fit-content;
}

.spanBold {
  color: #121212 !important;
}

.guidlinesText {
  font-family: DMSans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.41px;
  color: #121212;
  margin-top: 1em;
  margin-left: 1em;
}

.Guidelines {
  background: #ffffff;
  border: 1px solid #a890fe;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 16px 0px;
}

.campaignImage {
  width: 324px;
  height: 200px;
  border-radius: 5px;
}

.underImageContainer {
  flex-basis: 324px;
  display: flex;

  flex-wrap: wrap;
  box-sizing: border-box;
  width: fit-content;
}

.onlineMargin {
  margin-left: 0.75em;
}

.status {
  display: flex;

  align-items: center;
}

.guidlinesList {
  list-style-type: none;
}

.guidlinesList li::before {
  content: '\2022';
  color: #a890fe;
  font-weight: bold;
  display: inline-block;
  width: 16px;
  height: 8px;
}

.tags {
  width: 56px;
  height: 56px;
  background: #ffffff;
  border: 1px solid #a890fe;
  box-sizing: border-box;
  border-radius: 100px;
  position: relative;
  margin: 16px 0px;
}

.tagImage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.options {
  cursor: pointer;
  margin-left: auto;
}

.freebeeorange {
  color: #a890fe;
}

.navTextLogoDiv {
  display: flex;

  position: relative;
  align-items: center;
}

.navTextLogoDiv::after {
  position: absolute;
  border-bottom: 2px solid #a890fe;
  bottom: -0.75em;
  width: 100%;
  content: '';
}

.navTextLogoDiv > div:first-child {
  margin-right: 1em;
}

.navTextDisabled {
  display: flex;

  position: relative;
  align-items: center;
}
.navTextDisabled > div:first-child {
  margin-right: 1em;
}

/* Brand Settings */

select {
  font-family: DMSans;
  background-color: #ededed;
  border-radius: 5px;
  height: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(../assets/icons/ic_dropdown.svg);
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: -3px;
  border: none;
  border-radius: 5px;
  padding-right: 20px;
  padding-left: 10px;
  color: #4f4f4f;
  font-weight: bold;
  font-size: 10px;
}

.paddingContainerRight {
  padding-left: 50px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 600px) {
  .detailNav {
    justify-content: space-around;
  }
  .campaignImage {
    max-width: 324px;
    width: 100%;
  }
  .paddingContainerRight {
    padding-left: 0;
  }
}

.creditButton {
  color: white;
  background-color: #a890fe;
  height: 32px;
  line-height: 0;
  min-width: 154px;
}

.expertCard {
  margin-top: 16px;
  background-color: white;
  padding: 8px;
}

.body {
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  padding-bottom: 0;
  margin: 0 !important;
}

.link {
  text-decoration: underline;
  margin: 0 !important;
  padding: 0 !important;
}

.header {
  padding: 0 0 4px 0;
  margin: 0;
}
